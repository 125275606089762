import React, {useState} from 'react';
import {PreviewTile} from "./PreviewTile";
import {useFavouriteStore, useVideos} from "./api";
import cx from "classnames";
import {AutoSizer, List} from "react-virtualized";

function VideoScreen() {
    const {videos, loading} = useVideos();
    const {store, setFavourite} = useFavouriteStore();
    const [showOnlyFavourites, setShowOnlyFavourites] = useState<boolean>(false);

    const videosToShow = videos.filter(({id}) => !showOnlyFavourites || store[id]);

    // const totalDuration = useMemo(() => {
    //     return videos.reduce((total, { duration }) => total + duration, 0);
    // }, [videos]);

    return (
        <div className="bg-gray-200 flex flex-col h-full">

            <div className="px-4 py-2 bg-gray-800 text-white flex items-center">
                <h1 className="text-3xl">Videos</h1>
                {loading && <div className="ml-auto text-2xl font-light">Lädt...</div>}
                {!loading && <div className="ml-auto text-sm font-light">{videos.length} Videos geladen</div>}
                {/*{!loading && <div className="ml-auto text-sm font-light">{formatNanoseconds(totalDuration)}</div>}*/}
            </div>

            <div className="p-2 flex">
                <button
                    className={cx("px-4 py-2 rounded-l flex-grow", {
                        'bg-white': showOnlyFavourites,
                        'bg-blue-200 font-bold': !showOnlyFavourites,
                    })}
                    onClick={() => setShowOnlyFavourites(false)}
                >Alle Videos
                </button>
                <button
                    className={cx("px-4 py-2 rounded-r flex-grow", {
                        'bg-white': !showOnlyFavourites,
                        'bg-blue-200 font-bold': showOnlyFavourites,
                    })}
                    onClick={() => setShowOnlyFavourites(true)}
                >Favoriten
                </button>
            </div>

            <div className="relative flex-grow overflow-hidden">
                <AutoSizer>
                    {({width, height}) => (
                        <List
                            height={height}
                            width={width}
                            rowCount={videosToShow.length}
                            rowHeight={400}
                            rowRenderer={({key, index, isScrolling, isVisible, style}) => (
                                <div key={key} className="flex justify-center" style={style} data-debug={height}>
                                    <PreviewTile
                                        key={key}
                                        video={videosToShow[index]}
                                        isFavourite={store[videosToShow[index].id] || false}
                                        onFavourite={(v) => {
                                            setFavourite(videosToShow[index].id, v);
                                        }}
                                    />
                                </div>
                            )}
                        />
                    )}
                </AutoSizer>
            </div>
        </div>
    );
}

export default VideoScreen;