import React, {FormEvent, useCallback, useState} from "react";
import {useToken} from "./api";
export default function SignIn() {
    const { setToken } = useToken();

    const [password, setPassword] = useState<string>("");

    const onSubmit = useCallback((e: FormEvent) => {
        e.preventDefault();

        if (password === 'loewe30' && setToken) {
            setToken(btoa(password));
        } else {
            alert('Ungültiges Passwort');
        }

        return false;
    }, [setToken, password]);

    if (!setToken) {
        return null;
    }

    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
            <div className="max-w-md w-full">
                <div>
                    <h2 className="mt-6 text-center text-3xl leading-9 font-extrabold text-gray-900">
                        Passwort benötigt
                    </h2>

                </div>
                <form className="mt-8" action="#" method="POST" onSubmit={onSubmit}>
                    <div className="rounded-md shadow-sm">
                        <div className="">
                            <input aria-label="Passwort" name="password" type="password" required
                                   className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5"
                                   placeholder="Passwort"
                                   value={password}
                                   onChange={(e) => setPassword(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="mt-6">
                        <button type="submit"
                                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out">
          <span className="absolute left-0 inset-y pl-3">
            <svg className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400 transition ease-in-out duration-150"
                 fill="currentColor" viewBox="0 0 20 20">
              <path fillRule="evenodd"
                    d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                    clipRule="evenodd"/>
            </svg>
          </span>
                            Einloggen
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}