import React from "react";
import {VIDEO_BACKEND} from "./config";
import {VideoInfo} from "./types";
import cx from "classnames";
import {useToken} from "./api";
import {formatNanoseconds} from "./util";


type Props = {
  video: VideoInfo;
  isFavourite: boolean;
  onFavourite: (is: boolean) => void;
};

export const PreviewTile: React.FunctionComponent<Props> = ({ video, isFavourite, onFavourite }) => {
    const videoElement = React.useRef<HTMLVideoElement>(null);

    const { token } = useToken();

    return (
        <div className="relative w-full flex flex-col md:flex-row rounded-lg overflow-hidden m-4 shadow-lg bg-white">
            <div className="relative flex h-full">
                <video
                    controls
                    ref={videoElement}
                    style={{
                        objectFit: 'cover',
                    }}
                    poster={VIDEO_BACKEND + '/videos/' + video.id + '-02.jpg?token=' + token}
                    preload="none"
                >
                    <source src={VIDEO_BACKEND + '/videos/' + video.id + '.mp4?token=' + token} type="video/mp4"/>
                </video>
            </div>

            <div className="flex-shrink-0 p-4 flex md:flex-col align-center md:justify-center">
                {/*<span className="font-bold tracking-wide text-gray-700">*/}
                {/*    Unbekanntes Datum*/}
                {/*</span>*/}
                {/*<span>*/}
                {/*    {video.id}*/}
                {/*</span>*/}
                <div className="flex-grow"/>
                <span className="bg-blue-200 text-blue-700 px-4 py-1 rounded-full tracking-wide text-sm">
                  {formatNanoseconds(video.duration)}
                </span>
                <button className="ml-4 md:mx-auto md:mt-4" onClick={() => onFavourite(!isFavourite)}>
                    <svg className={cx("w-6 h-6 fill-current", {
                        'text-gray-300': !isFavourite,
                        'text-yellow-400': isFavourite,
                    })} viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10 15l-5.877853 3.09017 1.12257-6.545085L.489435 6.90983l6.571639-.954915L10 0l2.938926 5.954915 6.571639.954915-4.755282 4.635255 1.122569 6.545085z" fillRule="evenodd"/>
                    </svg>
                </button>
                {/*<button className="ml-4" onClick={() => {*/}
                {/*    */}
                {/*}}>*/}
                {/*    <svg className="w-5 h-5 fill-current text-gray-700" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">*/}
                {/*        <path d="M9 3.828427V8H8v2H6v8h8v-8h-2V8h-1v8H9V8H6.00853C4.90195 8 4 8.89925 4 10.00853v7.98294C4 19.09805 4.89925 20 6.00853 20h7.98294C15.09805 20 16 19.10075 16 17.99147v-7.98294C16 8.90195 15.10075 8 13.99147 8H11V3.828427l3.071068 3.071068L15.48528 5.48528 10.707107.707107 10 0 4.514719 5.485281l1.414213 1.414214L9 3.828427z" fillRule="evenodd"/>*/}
                {/*    </svg>*/}
                {/*</button>*/}
            </div>

        </div>
    );
};
