import React from 'react';
import VideoScreen from "./VideoScreen";
import {useToken} from "./api";
import SignIn from "./SignIn";

function App() {
    const { needsAuth } = useToken();

    if (!needsAuth) {
        return <VideoScreen/>
    }

    return (
        <SignIn />
    );
}

export default App;
