import {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {VideoInfo} from "./types";
import {VIDEO_BACKEND} from "./config";

function useLocalStorage<T>(key: string, initialValue: T): ([T, (v: T) => void]) {

    const readValue = useMemo(() => () => {
        try {
            // Get from local storage by key
            const item = window.localStorage.getItem(key);
            // Parse stored json or if none return initialValue
            return item ? JSON.parse(item) : initialValue;
        } catch (error) {
            // If error also return initialValue
            console.log(error);
            return initialValue;
        }
    }, [key, initialValue]);

    const [value, setValue] = useState<T>(readValue);

    const onChanged = useCallback(() => {
        setValue(readValue());
    }, [setValue, readValue]);

    const handler = useRef<() => void>();
    useEffect(() => {
        handler.current = onChanged;
    }, [onChanged]);

    useEffect(() => {

        const listener = () => handler.current && handler.current();

        document.addEventListener(`useLocalStorage.${key}`, listener);

        return () => document.removeEventListener(`useLocalStorage.${key}`, listener);
    }, [key, handler]);


    const set = (value: T) => {
        setValue(value);
        window.localStorage.setItem(key, JSON.stringify(value));
        document.dispatchEvent(new Event(`useLocalStorage.${key}`));
    };

    return [value, set];
}

type VideoInfoStatus = {
    loading: boolean;
    videos: VideoInfo[];
};

export const useVideos = (): Readonly<VideoInfoStatus> => {

    const { token } = useToken();
    const [loading, setLoading] = useState<boolean>(true);
    const [videos, setVideos] = useState<VideoInfo[]>([]);

    useEffect(() => {
        fetch(VIDEO_BACKEND + '/api/meta?token=' + token)
            .then(res => res.json())
            .then(videos => {
                setVideos(videos);
                setLoading(false);
            });
    }, [token, setVideos]);

    return { loading, videos };
};

type FavouriteStore = {
    [videoId: string]: boolean;
}

export const useFavouriteStore = (): {
    store: Readonly<FavouriteStore>;
    setFavourite: (videoId: string, isFavourite: boolean) => void,
} => {
    const [store, setStore] = useLocalStorage<FavouriteStore>('favourite_store', {});
    return {
        store,
        setFavourite: (videoId: string, isFavourite: boolean) => {
            setStore({
                ...store,
                [videoId]: isFavourite,
            });
        },
    };
};

type TokenHookResult =
    | { token: null, needsAuth: true, setToken: (token: string) => void, }
    | { token: string, needsAuth: false, setToken: undefined };

export const useToken = (): TokenHookResult => {
    const [token, setToken] = useLocalStorage<string | null>('token', null);
    if (token === null) {
        return { token, needsAuth: true, setToken }
    } else {
        return { token, needsAuth: false, setToken: undefined }
    }
};